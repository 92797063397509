<template>
  <div id="google-map-canvas" class="relative w-full rounded h-600-px shadow" ref="map"></div>
</template>

<script>
import {google, map_style} from "@/config/map";
import {createInfoWindow, getGoogleMaps, LatLng, myLocation} from "@/utils/map";


export default {
  name: "google-map",
  emits: ['onLocationMove'],
  props: {
    auto: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      map: {},
      pos: {
        lat: 41.54214231800138,
        lng: 60.63157875439325
      }
    }
  },
  mounted() {
    this.markers = [];
    this.initMap().then(map => this.map = map);
    this.getCurrentLocation().then((pos) => this.setPosition(LatLng(pos)))
  },
  methods: {
    setLocation({latitude, longitude}) {
      this.location = {
        lat: latitude,
        lng: longitude
      };

      this.setPosition(this.location)
    },
    setPosition(pos) {
      this.marker.setPosition(pos);
      // this.map.setCenter(pos);
      this.map.panTo(pos);
    },
    async initMap() { // main function
      const position = LatLng(this.pos)
      const opt = {
        center: position,
        mapTypeId: getGoogleMaps().MapTypeId.ROADMAP,
        zoom: 10, // scroll wheel: false,
        styles: map_style
      };

      const canvas = this.$refs.map; // document.getElementById("map-canvas");
      const map = new google.maps.Map(canvas, opt);
      const marker = new google.maps.Marker({
        position,
        map: map,
        title: "Asosiy",
        animation: google.maps.Animation.DROP
      });
      this.marker = marker;

      const infoWindow = createInfoWindow(google);
      marker.addListener('click', () => infoWindow.open(map, marker));

      google.maps.event.addListener(map, 'click', (event) => {
        let pos = event.latLng;
        marker.setPosition(pos);

        this.$emit('onLocationMove', {
          lat: pos.lat(),
          lng: pos.lng(),
        });
      });

      return map;
    },
    async getCurrentLocation() {
      if (!this.auto) {
        return this.pos;
      }

      try {
        const pos = await myLocation();
        const location = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };

        this.$emit('onLocationMove', location)

        return location;
      } catch (error) {
        this.$swal.fire({
          title: "Tizim to\u2018g\u2018ri ishlashi uchun joylashuvga ruxsat bering (yoki brauzeringizni yangilang)!",
          icon: 'info',
          confirmButtonText: 'Tushunarli',
          showCloseButton: true,
        });

        return this.pos;
      }
    },
    // Adds a marker to the map and push to the array.
    addMarker(position, map, model) {
      const marker = new google.maps.Marker({
        position,
        map,
        title: model.name,
        animation: google.maps.Animation.BOUNCE
      });
      this.markers.push(marker);
    }
  }
};
</script>
