<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Buyurtma berish</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="submit">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"></h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="phone" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Telefon raqamni kiriting
              </label>
              <input type="text" id="phone"
                     :class="inputClass"
                     placeholder="Telefon raqam"
                     autocomplete="off"
                     required
                     v-model="model.phone"
                     v-mask="'(##) ###-##-##'">
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="address" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Manzilni kiriting
              </label>

              <div class="flex rounded-lg shadow-sm">
                <select id="address"
                        v-if="isSelectVisible"
                        @input="onAddressChange"
                        :class="inputClass">
                  <option value="">Manzilni kiriting</option>
                  <option v-for="address of addressList" :key="address.id" :value="address.id">{{ address.name }}</option>
                </select>
                <input v-else type="text" id="address"
                       :class="inputClass"
                       placeholder="Mijoz manzili"
                       autocomplete="off"
                       v-model="model.address">
                <button type="button"
                        title="Manzilni qo&#8216;lda kiritish"
                        class="px-4 rounded-r-lg inline-flex items-center min-w-fit border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                        @click="changeSelectInput()">
                  <span class="text-sm text-gray-500 dark:text-gray-400">
                    <i class="fa-solid fa-repeat"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"></h6>
        <div class="flex justify-between items-center">

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative  mb-3">
              <label :for="'payment_type'" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                To&#8216;lov turini tanlang
              </label>
              <select id="payment_type" v-model="model.payment_type" :class="inputClass">
                <option :value="booking.PAYMENT_TYPE_CASH" selected>Naqd</option>
                <option :value="booking.PAYMENT_TYPE_CASH">Plastik</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="tariff" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Tarifni tanlang
              </label>
              <select id="tariff"
                      v-model="model.tariff_id"
                      :class="inputClass">
                <option value="0">Tarif tanlanmadi</option>
                <option v-for="tariff of tariffList" :key="tariff.id" :value="tariff.id">{{ tariff.name }}</option>
              </select>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="description" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Mijoz haqida qo&#8216;shimcha
              </label>
              <input type="text" id="description"
                     :class="inputClass"
                     placeholder="Qo&#8216;shimcha"
                     autocomplete="off"
                     v-model="model.description">
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300 border-none">
        <div class="w-full px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <google-map @onLocationMove="onLocationMove" ref="map" :tariff_id="model.tariff_id"/>
          </div>
        </div>

        <br>
        <div>
          <div class="flex justify-between">
            <div class="justify-start">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Boshlang&#8216;ich to&#8216;lov</label>
              <input
                type="number"
                @focus="onInputFocus"
                @blur="onInputBlur"
                name="payment"
                v-model="model.payment"
                placeholder="0"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </div>
            <div class="flex items-center mr-4">
              <div class="mr-4">
                <input @click="changeCheckboxInput"
                       v-model="model.in_turn"
                       class="mr-1 shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                       type="checkbox">
                <label class="font-bold uppercase">Navbat bilan</label>
              </div>
              <button type="submit"
                      class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                E&#8217;lon berish
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask';
import GoogleMap from "@/views/operator/components/maps/GoogleMap.vue";
import booking from "@/data/booking";


export default {
  name: "map-form",
  computed: {
    booking() {
      return booking
    }
  },
  directives: {mask},
  components: {
    GoogleMap
  },
  data() {
    return {
      inputClass: 'border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150',
      model: {
        payment_type: 1,
        tariff_id: 0,
        payment: 0,
      },
      isSelectVisible: true,
      in_turn: false,
      addressList: [{
        id: 1,
        latitude: 0,
        longitude: 0,
        name: "",
        text: "",
        target: ""
      }],
      tariffList: [{
        id: 1,
        name: '',
        description: ''
      }],
      canSubmit: false
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch('get', {url: this.$route.path})
        .then(res => this.model = res)
    } else {
      this.model.phone = this.$store.state.form.phone;
    }

    this.$store.dispatch('get', {url: "/operator/addresses"})
      .then(res => this.addressList = res?.data?.addresses)

    this.$store.dispatch('get', {url: "/operator/tariff/list"})
      .then(res => this.tariffList = res.data.tariffs)

  },
  watch: {
    "$store.state.form.phone"(nextValue) {
      this.model.phone = nextValue;
    }
  },
  methods: {
    submit() {
      this.model.phone = this.unmask(this.model.phone)
      this.$store.dispatch("post", {
        url: '/operator/booking/create',
        model: this.model
      }).then((res) => {
          if (res.success) {
            this.model = {
              payment_type: 1,
              tariff_id: 0
            }

            this.in_turn = false
            this.$store.commit('FILL_BOOKING_FORM', "")
            this.$swal.fire({
              icon: 'success',
              title: "Success",
              html: "Buyurtma muvaffaqiyatli berildi!",
              toast: true,
              position: "top-end",
              timer: 3000,
              showConfirmButton: false
            });

            this.isSelectVisible = true;
          } else {
            this.$swal({
              title: "Error!",
              text: res.msg,
              icon: "error",
              confirmButtonText: "OK"
            })
            this.canSubmit = true
          }
        },
        () => this.$router.push({name: 'operator.bookings.in_process'})
      );
    },
    onInputFocus() {
      this.payment = ''
    },
    onInputBlur() {
      this.payment = 0
    },
    changeCheckboxInput() {
      this.in_turn = !this.in_turn
    },
    changeSelectInput() {
      this.isSelectVisible = !this.isSelectVisible
    },
    onAddressChange(e) {
      let val = e.target.value;
      if (!val) {
        return
      }

      let address = this.addressList.find(item => item.id.toString() === val)
      this.model.address = address.name;
      this.model.latitude = address.latitude;
      this.model.payment = address.payment;
      this.model.longitude = address.longitude;

      this.$refs.map.selectLocation(address)
    },
    onLocationMove(pos) {
      this.model.latitude = pos.lat();
      this.model.longitude = pos.lng();
    }
  }
}
</script>
