export default {
    BOOKING_CREATED: 0,
    BOOKING_TAKEN: 2,
    BOOKING_ARRIVED: 3,
    BOOKING_CANCELLED: -1,

    // Type
    TYPE_CALL: 1,
    TYPE_CLIENT: 2,
    TYPE_STREET: 3,
    TYPE_TELEGRAM_BOT: 4,

    // Payment
    PAYMENT_TYPE_CASH: 1,
    PAYMENT_TYPE_CREDIT_CARD: 2,
}
