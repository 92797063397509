<template>
  <div class="py-2">
    <nav class="block">
      <ul class="flex pl-0 rounded list-none flex-wrap">
        <li v-for="(link, i) in links" :key="link.label">
          <button
            @click.prevent="changePage(link)"
            :class="activePage(link.active)"
            :disabled="!link.url"
          >
            <span v-if="i === 0">
              <i class="fas fa-chevron-left -ml-px"></i>
            </span>
            <span v-else-if="isLastPage(i)">
              <i class="fas fa-chevron-right -mr-px"></i>
            </span>
            <span v-else>{{ link.label }}</span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "table-pagination",
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  emits: ['update'],
  data() {
    return {
      currentPage: 1,
      numerationStyle: "first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500 "
    };
  },
  methods: {
    changePage(link) {
      this.$emit('update', {
        url: link.url
      });
    },
    activePage(isActive) {
      return this.numerationStyle + (isActive ? 'text-white bg-emerald-500' : 'bg-white text-emerald-500');
    },
    isLastPage(index) {
      return index === this.links.length - 1;
    }
  },
}
</script>

<style scoped>
/* Your styles here */
</style>
