<template>
  <div class="flex flex-wrap ml-3 mt-3">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button @click="onMicrophoneClick">
      <i v-if="microphone" class="fas fa-microphone-alt"></i>
      <i v-else class="fas fa-microphone-alt-slash"></i>
    </button>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button @click="onPlayClick">
      <i v-if="speaker" class="fas fa-volume-up"></i>
      <i v-else class="fas fa-volume-mute"></i>
    </button>
  </div>
</template>

<script>

import {host} from "@/config";
import radioConfig from "@/config/radio";

export default {
  name: "media-component",
  emits: ['onSpeakerStop'],
  props: {
    audios: {
      type: Array
    },
  },
  data: () => ({
    microphone: null,
    speaker: false,
    isAudioPlaying: false
  }),
  methods: {
    play() {
      if (this.audios.length < 1) {
        this.isAudioPlaying = false;
        return;
      }

      this.isAudioPlaying = true;

      let audio = new Audio();
      audio.src = host + this.audios[0];
      audio.onloadeddata = () => {
        audio.play();
      }

      audio.onended = () => {
        // eslint-disable-next-line vue/no-mutating-props
        this.audios.shift();
        this.play();
      }
    },
    onPlayClick() {
      if (this.speaker) {
        this.$emit('onSpeakerStop')
      }

      this.speaker = !this.speaker;

      setInterval(() => {
        if (!this.isAudioPlaying) {
          this.play();
        }
      }, 100);
    },
    async onMicrophoneClick() {
      if (this.microphone) {
        this.microphone.stop();
        this.microphone = null;
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });

      this.microphone = stream.getTracks()[0];
      const recorder = new MediaRecorder(stream)

      const chunks = [];
      recorder.ondataavailable = event => {
        // get the Blob from the event
        const blob = event.data;
        chunks.push(blob);
      };

      // make data available event fire every one second
      recorder.start(radioConfig.recorderInterval);
      recorder.onstop = () => {
        const blobData = new Blob(chunks, {
          // type: 'audio/webm;codecs=opus'
        });

        let formData = new FormData();
        formData.set('agent', "operator");
        formData.set('record', blobData);

        this.$store.dispatch('post', {
          url: "/operator/record",
          model: formData
        }, {
          'Content-Type': "multipart/form-data"
        });
      }
    }
  }
}
</script>
