<template>
  <table-layout heading="O'tkazmalar" :headers="headers" :addButton="false" :addDates="true" @fetchData="setDate">
    <tr v-for="(model, i) in data.data" :key="i">
      <td :class="rowClass">
        {{ model.id }}
      </td>
      <td :class="rowClass">
        {{ model.driver?.name }} {{ model.driver?.surname }}
      </td>
      <td :class="rowClass">
        {{ model.amount }}
      </td>
      <td :class="rowClass">
        {{ model.user?.name }} {{ model.user?.surname }}
      </td>
      <td :class="rowClass">
        {{ formatDate(model.created_at) }}
      </td>
    </tr>
  </table-layout>
  <table-pagination :links="data.links" @update="fetchData"/>
</template>

<script>

import avatar from "@/assets/img/avatar.svg";
import TableLayout from "@/views/components/Tables/TableLayout.vue";
import TablePagination from "@/views/components/Tables/TablePagination.vue";
import {formatDate} from "@/utils/map";

export default {
  name: "transactions-index",
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      rowClass: 'border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4',
      avatar,
      headers: [
        "ID", "Haydovchi", "O'tkazma miqdori", "Kim tomonidan", "O'tkazilgan sana", ""
      ],
      data: {
        current_page: 1,
        links: []
      },
    }
  },
  mounted() {
    this.fetchData({
      url: '/admin/transactions',
    })
  },
  methods: {
    formatDate,
    fetchData(link) {
      let url = link.url
      this.$store.dispatch('get', {url}).then(res => this.data = res.data)
    },
    setDate(date) {
      this.$store.dispatch('get', {url: '/admin/transactions', params: date}).then(res => this.data = res.data)
    }
  },
  components: {
    TablePagination,
    TableLayout
  },
}
</script>

<style scoped>

</style>
