<template>
    <router-view/>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";


export default {
    name: 'App'
}
</script>
