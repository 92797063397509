<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Sozlamalar</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="submit">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Ma'lumotlarni yangilash
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="brand" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Tashkilot nomi
              </label>
              <input type="text" id="name"
                     :class="inputClass"
                     placeholder="Tashkilot nomi"
                     autocomplete="off"
                     v-model="model.name">
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="model" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Telefon raqami
              </label>
              <input type="text" id="manager_phone" name="manager_phone"
                     :class="inputClass"
                     placeholder="Telefon raqami"
                     v-mask="'(##) ###-##-##'"
                     autocomplete="off"
                     v-model="model.manager_phone">
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="model" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Qisqa raqami
              </label>
              <input type="text" id="short_phone" name="short_phone"
                     :class="inputClass"
                     placeholder="Qisqa raqam"
                     v-mask="'##-##'"
                     autocomplete="off"
                     v-model="model.short_phone">
            </div>

          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="radius" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Ishlash radiusi
              </label>
              <input type="text" id="radius" name="radius"
                     :class="inputClass"
                     placeholder="Radius"
                     autocomplete="off"
                     v-model="model.radius">
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="model" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Kenglik
              </label>
              <input type="text" id="latitude" name="latitude"
                     :class="inputClass"
                     placeholder="Kenglik"
                     autocomplete="off"
                     v-model="model.latitude"
                     readonly
                     required>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label for="model" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Uzunlik
              </label>
              <input type="text" id="longitude" name="longitude"
                     :class="inputClass"
                     placeholder="Uzunlik"
                     autocomplete="off"
                     v-model="model.longitude"
                     readonly
                     required>
            </div>
          </div>
        </div>
        <div class="w-full px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <google-map @onLocationMove="setPosition" ref="map"/>
          </div>
        </div>
        <div class="flex flex-wrap justify-end">
          <button type="submit"
                  class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3
                  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Saqlash
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import GoogleMap from "@/views/components/maps/GoogleMap.vue";

export default {
  name: "admin-settings",
  directives: {mask},
  components: {
    GoogleMap
  },
  data() {
    return {
      inputClass: 'border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150',
      data: {},
      model: {},
      auto: false,
      inputMask: '## XXXXXX',
    }
  },
  mounted() {
    this.$store.dispatch('get', {url: '/admin/company/get/'})
      .then(res => {
        this.model = res.data
        this.$refs.map.setLocation({
          latitude: parseFloat(this.model.latitude),
          longitude: parseFloat(this.model.longitude),
        })
    })
  },
  methods: {
    submit() {
      this.model.manager_phone = this.unmask(this.model.manager_phone)
      this.model.short_phone = this.unmask(this.model.short_phone)
      let method = this.$route.params.id ? "put" : "post";
      this.$store.dispatch(method, {
        url: `/admin/company/set/${this.$route.params.id ?? ''}`,
        model: {...this.model}
      }).then((res => {
        if (res.success) {
          this.$swal.fire({
            icon: "success",
            title: "Muvaffaqiyatli",
            html: "Sozlamalar yangilandi!",
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false
          })
        } else {
          this.$swal({
            title: "Xato!",
            text: "Xato kiritildi!",
            icon: "error",
            showConfirmButton: "OK"
          })
          this.canSubmit = true
        }
      }))
    },
    setPosition(pos) {
      this.model.latitude = pos.lat;
      this.model.longitude = pos.lng;
    },
  },
}
</script>
