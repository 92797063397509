<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
       :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            {{ heading }}
          </h3>
        </div>
        <div class="relative px-4">
          <input type="search" id="text"
                 :class="inputClass"
                 placeholder="Qidirish"
                 @input="onSearch"
                 v-model="text"
                 autocomplete="off">
        </div>
        <router-link :to="link" v-if="addButton"
                     class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
          Yaratish
        </router-link>

        <div v-if="addDates">
          <input v-model="from" type="date" :class="defaultClass" :max="to">
          <input v-model="to" type="date" :class="defaultClass" :min="from">
          <button type="button" :disabled="isDisabled" :class="'bg-emerald-500 text-white ' + defaultClass" @click="reloadEvent">
            <i class="text-sm fas fa-solid fa-rotate-right"></i>
          </button>
        </div>

      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                v-for="(header, i) in headers" :key="i"
                :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <slot/>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: "table-layout",
  emits: ['fetchData', 'search'],
  props: {
    heading: {
      default: ""
    },
    headers: {
      default: []
    },
    link: {
      default: ""
    },
    addButton: {
      type: Boolean,
      default: true
    },
    addDates: {
      type: Boolean,
      default: false
    },
    color: {
      default: "light",
      validator: function(value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      text: "",
      from: '',
      to: '',
      defaultClass: "font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150",
      inputClass: 'border-0 px-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150',
    }
  },
  computed: {
    isDisabled() {
      return Boolean(!this.from) && Boolean(!this.to)
    }
  },
  methods: {
    reloadEvent() {
      this.$emit('fetchData', {from: this.from, to: this.to});
    },
    onSearch() {
      this.$emit('search', this.text);
    }
  }
};
</script>
