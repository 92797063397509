import Statistics from "@/views/admin/Statistics/index.vue";
import Transactions from "@/views/admin/Transactions/index.vue";
import BookingsHistory from "@/views/admin/Bookings/history.vue";
import EmptyLayout from "@/views/layouts/EmptyLayout";
import Operators from "@/views/admin/Operators";
import OperatorForm from "@/views/admin/Operators/form";
import Drivers from "@/views/admin/Drivers";
import DriverForm from "@/views/admin/Drivers/form";
import Tariffs from "@/views/admin/Tariffs";
import TariffForm from "@/views/admin/Tariffs/form";
import CarTypes from "@/views/admin/CarTypes";
import CarTypeForm from "@/views/admin/CarTypes/form";
import Branches from "@/views/admin/Branches/index.vue";
import DistrictForm from "@/views/admin/Branches/form";
import Points from "@/views/admin/Addresses";
import PointForm from "@/views/admin/Addresses/form";
import Process from "@/views/admin/Booking/index.vue"
import Settings from "@/views/admin/Settings/form.vue"
import Clients from "@/views/admin/Clients/index.vue";
import {ADMIN, OPERATOR} from "@/config/role";

export const admin = [
    {
        path: "/dashboard",
        label: "Ko\u2018rsatkichlar",
        icon: "fas fa-chart-line",
        meta: {auth: true, role: ADMIN},
        component: Statistics,
    },
    {
        path: "/bookings-history",
        label: "Buyurtmalar tarixi",
        icon: "fas fa-solid fa-book",
        section: 2, // Show under divider in sidebar
        meta: {auth: true, role: ADMIN},
        component: BookingsHistory,
    },
    {
        path: "/transactions",
        label: "O\u2018tkazmalar tarixi",
        icon: "fas fa-money-bill",
        section: 2, // Show under divider in sidebar
        meta: {auth: true, role: ADMIN},
        component: Transactions,
    },
    {
        path: "/operators",
        label: "Operatorlar",
        name: "admin.operators",
        icon: "fas fa-headphones",
        meta: {auth: true, role: ADMIN},
        component: EmptyLayout,
        children: [
            {
                path: "",
                name: "admin.operators.index",
                component: Operators,
                meta: {auth: true, role: ADMIN},
            },
            {
                path: "create",
                component: OperatorForm,
                meta: {auth: true, role: ADMIN},
            },
            {
                path: ":id",
                component: OperatorForm,
                meta: {auth: true, role: OPERATOR},
            },
        ],
    },
    {
        path: "/drivers",
        label: "Haydovchilar",
        name: "admin.drivers",
        icon: "fas fa-taxi",
        meta: {auth: true, role: ADMIN},
        component: EmptyLayout,
        children: [
            {
                path: "",
                name: "admin.drivers.index",
                component: Drivers,
            },
            {
                path: "create",
                component: DriverForm,
            },
            {
                path: ":id",
                component: DriverForm,
            },
        ],
    },
    {
        path: "/tariffs",
        label: "Tariflar",
        icon: "fas fa-file-circle-exclamation",
        meta: {auth: true, role: ADMIN},
        component: EmptyLayout,
        children: [
            {
                path: "",
                component: Tariffs,
            },
            {
                path: "create",
                component: TariffForm,
            },
            {
                path: ":id",
                component: TariffForm,
            },
        ],
    },
    {
        path: "/car-types",
        label: "Mashina turlari",
        icon: "fas fa-taxi",
        meta: {auth: true, role: ADMIN},
        component: EmptyLayout,
        children: [
            {
                path: "",
                component: CarTypes,
            },
            {
                path: "create",
                component: CarTypeForm,
            },
            {
                path: ":id",
                component: CarTypeForm,
            },
        ],
    },
    {
        path: "/clients",
        label: "Mijozlar",
        icon: "fa-solid fa-users",
        meta: {auth: true, role: ADMIN},
        component: Clients,
    },
    {
        path: "/addresses",
        label: "Manzillar",
        icon: "fas fa-location",
        meta: {auth: true, role: ADMIN},
        component: EmptyLayout,
        redirect: {name: "addresses"},
        children: [
            {
                path: "",
                component: Points,
            },
            {
                path: "create",
                component: PointForm,
            },
            {
                path: ":id",
                component: PointForm,
            },
        ],
    },
    {
        path: "/branches",
        label: "Bo\u2018limlar",
        icon: "fas fa-city",
        meta: {auth: true, role: ADMIN},
        component: EmptyLayout,
        children: [
            {
                path: "",
                component: Branches,
            },
            {
                path: "create",
                component: DistrictForm,
            },
            {
                path: ":id",
                component: DistrictForm,
            },
        ],
    },
    {
        path: "/settings",
        label: "Sozlamalar",
        icon: "fa-solid fa-wrench",
        section: 3, // Show under divider in sidebar
        meta: {auth: true, role: ADMIN},
        component: Settings,
    },
    {
        path: "/process",
        label: "Buyurtmalar",
        icon: "fas fa-file-circle-exclamation",
        section: 4,
        meta: {auth: true, role: ADMIN},
        component: Process,
    }
];
