<template>
  <table-layout heading="Mijozlar" :headers="headers" :addButton="false">
    <tr v-for="(model, i) in data.data" :key="i">
      <th :class="rowClass">
        {{ i + 1 }}
      </th>
      <th :class="rowClass">
        {{ model.id }}
      </th>
      <td :class="rowClass">
        {{ model.name }}
      </td>
      <td :class="rowClass">
        {{ model.phone }}
      </td>
      <td :class="rowClass">
        {{ model.balance?.amount }}
      </td>
      <td :class="rowClass">
        {{ model.balance?.bonus }}
      </td>
      <td :class="rowClass">
        {{ model.balance?.booking_limit }}
      </td>
      <td :class="rowClass">
        {{ formatDate(model.created_at) }}
      </td>
    </tr>
  </table-layout>
  <table-pagination :links="data.links" @update="fetchData" />
</template>

<script>
import TableLayout from "@/views/components/Tables/TableLayout.vue";
import {formatDate} from "@/utils/map";
import TablePagination from "@/views/components/Tables/TablePagination.vue";


export default {
  name: "clients-table",
  data() {
    return {
      rowClass: 'border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4',
      btnClass: 'text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150',
      headers: ["Tr", "ID", "Mijoz ismi", "Telefon raqam", "shaxsiy mablag‘i", "Bonus", "Bonus buyurtmalar soni", "Sana", ""],
      data: {
        current_page: 1,
        links: []
      },
    }
  },
  mounted() {
    this.fetchData({
      url: '/admin/clients'
    })
  },
  methods: {
    formatDate,
    fetchData(link) {
      let url = link.url;
      this.$store.dispatch('get', {url}).then(res => this.data = res.data)
    },
  },
  components: {
    TablePagination,
    TableLayout
  }
}
</script>
