<template>
  <table-layout heading="Buyurtmalar" :headers="headers" :addButton="false" :addDates="true" @fetchData="getData">
    <tr v-for="model in data.data" :key="model.id">
      <td :class="rowClass">
        {{ model.id }}
      </td>
      <td :class="rowClass">
        {{ phoneFormatter(model.client?.phone) }}
      </td>
      <td :class="rowClass">
        {{ model.driver?.name }}
      </td>
      <td :class="rowClass">
        {{ model.duration }}
      </td>
      <td :class="rowClass">
        {{ model.distance }}
      </td>
      <td :class="rowClass">
        {{ model.fare }}
      </td>
      <td :class="rowClass">
        {{ model.service_fee }}
      </td>
      <td :class="rowClass">
        <i :class="'fas fa-circle mr-2 ' + statusClass(model.status)"></i>
        <span>{{ statusText(model.status) }}</span>
      </td>
      <td :class="rowClass">
        <span>{{ bookingMaker(model.type) }} ({{ model.user?.name }})</span>
      </td>
      <td :class="rowClass">
        {{ formatDate(model.created_at) }}
      </td>
      <td :class="rowClass">
        {{ formatDate(model.updated_at) }}
      </td>
    </tr>
  </table-layout>
  <table-pagination :links="data.links" @update="getData"/>
</template>

<script>
import avatar from "@/assets/img/avatar.svg";
import TableLayout from "@/views/components/Tables/TableLayout.vue";
import TablePagination from "@/views/components/Tables/TablePagination.vue";
import {formatDate} from "@/utils/map";

export default {
  name: "booking-history-index",
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      rowClass: 'border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4',
      avatar,
      headers: [
        "ID", "Mijoz", "Haydovchi", "Daqiqa", "Km", "Yo\u2018l haqi", "Xizmat haqi", "Holati", "Kim tomonidan", "Yaratildi", "Tugatildi", ""
      ],
      queryParams: {},
      data: {
        current_page: 1,
        links: []
      },
    }
  },
  components: {
    TablePagination,
    TableLayout
  },
  mounted() {
    this.getData({
      url: '/admin/bookings-history/all',
    })
  },
  methods: {
    formatDate,
    getData(link) {
      let url = link.url;
      this.$store.dispatch('get', {url}).then(res => this.data = res.data)
    },
    // fetch(params) {
    //   this.queryParams = {...this.queryParams, ...params}
    //   this.getData()
    bookingMaker(type) {
      if (type === 1) {
        return 'Operator orqali'
      }

      if (type === 2) {
        return 'Ilova orqali'
      }

      if (type === 3) {
        return 'Ko\u2018chadan olingan'
      }

      if (type === 4) {
        return 'Bot orqali'
      }
    },
    statusText(status) {
      if (status === 1) {
        return 'tugallangan'
      } else {
        return 'bekor qilingan'
      }
    },
    statusClass(status) {
      if (status === 1) {
        return 'text-emerald-500'
      } else {
        return 'text-red-500'//'text-teal-500'
      }
    }
  }
}
</script>

<style scoped>

</style>
