<template>
  <div>
    <sidebar>
      <Media :audios="audios" @onSpeakerStop="onStop"/>
    </sidebar>
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar/>
      <div class="relative bg-emerald-600 md:pt-32 pb-16 pt-12">
        <div class="px-4 md:px-10 mx-auto w-full">
          <div>
            <div class="flex flex-wrap"></div>
          </div>
        </div>
      </div>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view/>
        <footer-admin/>
      </div>
    </div>
  </div>
</template>

<script>

import Sidebar from "@/views/layouts/Sidebar/Operator/Sidebar.vue";
import AdminNavbar from "@/views/layouts/Navbars/AdminNavbar.vue";
import FooterAdmin from "@/views/layouts/Footers/FooterAdmin.vue";
import Media from "@/views/components/Media/index.vue";

import toastComponent from "@/views/operator/components/toast.vue";
import socketAction from "@/data/action";

import {formatMonthDay} from "@/utils/formatter";
import {useToast} from "vue-toastification";
import {getBooking, getBookingList, removeBooking, setBooking} from "@/utils/storage";


export default {
  name: "operator-layout",
  components: {
    Sidebar,
    AdminNavbar,
    FooterAdmin,
    Media
  },
  data() {
    return {
      audios: []
    }
  },
  setup() {
    const toast = useToast();
    // Make it available inside methods
    return {toast}
  },
  mounted() {
    let bookings = getBookingList()
    this.toast.clear();

    for (let i in bookings) {
      setTimeout(() => {
        this.showDialog(bookings[i])
      }, 500 + 20 * (i + 1))
    }
  },
  sockets: {
    'server-operator'(data) {
      if (data.action === socketAction.AUDIO) {
        this.audios.push(data.audio);
        return;
      }

      let {action, booking} = data;
      if (action === socketAction.TRANSMIT) {
        let entity = getBooking(booking.phone)
        if (entity) {
          return
        }

        setBooking(booking);
        this.showDialog(booking)
      }
    }
  },
  methods: {
    showDialog(booking) {
      const content = {
        // Your component or JSX template
        component: toastComponent,

        // Props are just regular props, but these won't be reactive
        props: {
          bookingName: 'Yangi buyurtma',
          phone: this.phoneFormatter(String(booking.phone)),
          date: formatMonthDay(String(booking.date))
        }
      };

      // These options will override the options defined in the "app.use" plugin registration for this specific toast
      let toastId = this.toast.info(content, {
        position: "top-right",
        timeout: false,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        onClick: () => {
          this.toast.dismiss(toastId)

          // Open form for autofill
          this.takeBooking(booking);
        },
        onClose: () => {
        }
      });
    },
    takeBooking(booking) {
      // Notify about taking this booking
      this.$socket.emit('operator-server', {
        action: socketAction.TAKE,
        booking: {
          id: booking.id,
          phone: booking.phone
        }
      });

      //
      removeBooking(booking.phone);

      // Store this booking
      this.$store.commit('FILL_BOOKING_FORM', booking.phone)

      // Open reserve booking page
      this.$router.push('/bookings/create')
    },
    onStop() {
      this.audios = [];
    }
  }
}
</script>
