import  {OPERATOR} from "@/config/role";
import BookingCreate from "@/views/operator/booking/form.vue";
import Drivers from "@/views/operator/driver/index.vue";
import BookingsInProcess from "@/views/operator/booking/index.vue";


export const operator = [
    {
        path: "/bookings/create",
        label: "Buyurtma qo\u2018shish",
        icon: 'fas fa-address-book',
        name: 'operator.bookings.create',
        meta: {auth: true, role: OPERATOR},
        component: BookingCreate
    },
    {
        path: "/bookings",
        label: "Buyurtmalar",
        icon: 'fas fa-stream',
        name: 'operator.bookings.in_process',
        meta: {auth: true, role: OPERATOR},
        component: BookingsInProcess,
    },
    {
        path: "/drivers/active",
        label: "Haydovchilar",
        icon: 'fas fa-taxi',
        name: 'operator.drivers.index',
        meta: {auth: true, role: OPERATOR},
        component: Drivers,
    }
]
