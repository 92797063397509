import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";

export const options = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: false
}

export default Toast;
