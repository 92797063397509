<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="Buyurtmalar"
              :statTitle= "bookings.current"
              :statArrow="bookings.current > bookings.previous ? 'up' : 'down'"
              :statPercent="bookingsPercent"
              :statPercentColor="arrowColor(bookingsPercent)"
              :statDescripiron="'Kecha: ' + bookings.previous"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="Mijozlar"
              :statTitle="clients.current"
              :statArrow="clients.current > clients.previous ? 'up' : 'down'"
              :statPercent="clientPercent"
              :statPercentColor="arrowColor(clientPercent)"
              :statDescripiron="'Oldingi oy: ' + clients.previous"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="Haydovchilar"
              :statTitle="drivers.current"
              :statArrow="drivers.current > drivers.previous ? 'up' : 'down'"
              :statPercent="driversPercent"
              :statPercentColor="arrowColor(driversPercent)"
              :statDescripiron="'Oldingi oy: ' + drivers.previous"
              statIconName="fa-regular fa-id-card"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="Kunlik tushum"
              :statTitle="incomes.current"
              :statArrow="incomes.current > incomes.previous ? 'up' : 'down'"
              :statPercent="incomePercent"
              :statPercentColor="arrowColor(incomePercent)"
              :statDescripiron="'Kecha: ' + incomes.previous"
              statIconName="fa-solid fa-money-bill-trend-up"
              statIconColor="bg-pink-500"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/views/admin/components/cards/CardStats.vue";

export default {
  components: {
    CardStats,
  },

  data() {
    return {
      bookings: {previous: 0},
      clients: {previous: 0},
      drivers: {previous: 0},
      incomes: {previous: 0},
      bookingsPercent: 0,
      incomePercent: 0,
      clientPercent: 0,
      driversPercent: 0
    }
  },

  async mounted() {
    try {
      const res = await this.$store.dispatch('get', {url: '/admin/reports'})
      const data = res.data
      this.bookings = data.bookings
      this.clients = data.clients
      this.drivers = data.drivers
      this.incomes = data.incomes
    } catch (error) {
      throw new Error(error)
    }
    this.bookingsPercent = this.calculateIncreasePercent(this.bookings.previous, this.bookings.current)
    this.clientPercent = this.calculateIncreasePercent(this.clients.previous, this.clients.current)
    this.driversPercent = this.calculateIncreasePercent(this.drivers.previous, this.drivers.current)
    this.incomePercent = this.calculateIncreasePercent(this.incomes.previous, this.incomes.current)
  },
  methods: {
    calculateIncreasePercent(originalNumber, newNumber) {
      if (originalNumber === 0) {
        // Handle division by zero
        return newNumber === 0 ? 0 : 'O\u2018sish'; // Or handle it according to your needs
      }
      let increasePercent = ((newNumber - originalNumber) / originalNumber) * 100;
      return Number(increasePercent.toFixed(1)); // Round to two decimal places
    },
    arrowColor(percent) {
      if (percent > 0) {
        return 'text-emerald-500'
      }

      if (percent < 0) {
        return 'text-red-500'
      } else {
        return 'text-orange-500'
      }
    }
  }
};
</script>
