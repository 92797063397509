// Vue masked text
import StringMask from 'string-mask';

// Formatter for phone number
const phone = new StringMask("(##) ###-##-##");

// Formatter for price
const price = new Intl.NumberFormat('uz-Uz', {
    style: 'decimal'
});

// Unmask
function unmask(value) {
    return value.toString().replaceAll(/([A-Za-z- ()])/g, '')
}

export const mask = {
    methods: {
        phoneFormatter: value => phone.apply(value),
        price: value => price.format(value) + "\rso\u2018m",
        unmask: value => unmask(value)
    }
}
