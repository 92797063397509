<template>
  <div class="container w-full">
    <div class="flex items-start justify-between">
      <div class="flex flex-col">
        <h4 class="text-sm">{{ bookingName }}</h4>
        <h5 class="text-xs">{{ phone }}</h5>
      </div>
      <div>
        <h6 class="text-xs">{{ date }}</h6>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: 'toast-component',
  methods: {},
  props: ['bookingName', 'phone', 'date']
}
</script>
