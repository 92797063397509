import VueSocketIO from 'vue-3-socket.io';
import SocketIO from 'socket.io-client';

import {getCookie} from "@/utils/storage";

const token = getCookie('token');

export default function io(store, url = process.env.VUE_APP_SOCKET_URL) {
    return new VueSocketIO({
        connection: SocketIO(url, {
            extraHeaders: {
                "agent": "operator",
                "secret-key": JSON.parse(token),
            },
            query: {},
            transports: ['polling', 'websocket'],
            secure: true
        }),
        vuex: {
            store,
            // actionPrefix: 'SOCKET_',
            // mutationPrefix: 'SOCKET_'
        },
        options: {path: "/"}
    });
}
