import {useCookies} from "vue3-cookies";

import {storageName} from "@/config";
import {parse, stringify} from "@/utils/serializer";

const {cookies} = useCookies();


export function getToken(name) {
    return getCookie(name)?.access_token;
}

export function getRole() {
    return getCookie(storageName)?.user?.role_id;
}

/**** Cookie ****/
export function getCookie(name) {
    return cookies.get(name)
}

export function setCookie(name, data) {
    cookies.set(name, stringify(data), "8h");
}

export function removeCookie(name) {
    cookies.remove(name);
}

/**** ~ Cookie ~ ****/


let bookings = new Map();

export function setBooking(booking) {
    bookings.set(booking.phone, booking)
    localStorage.setItem("bookings", stringify(Array.from(bookings.values())))
}

export function getBooking(phone) {
    return bookings.get(phone)
}

export function getBookingList() {
    let list = parse(localStorage.getItem("bookings")) || [];
    bookings = new Map(list.map(book => (
        [book.phone, book]
    )));

    return list;
}

export function removeBooking(phone) {
    bookings.delete(phone)
    localStorage.setItem("bookings", stringify(Array.from(bookings.values())))
}
