<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            Buyurtmalar soni
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold">
            Holat bo&#8216;yicha
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto">
      <div class="relative h-350-px">
        <canvas id="bar-chart"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
export default {
  data() {
    return {
      statistics: {
        completed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        canceled: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    }
  },
  mounted: function () {
    this.fetchData()
    this.$nextTick(function () {
      let config = {
        type: "bar",
        data: {
          labels: [
            "Yanvar",
            "Fevral",
            "Mart",
            "Aprel",
            "May",
            "Iyun",
            "Iyul",
            "Avgust",
            "Sentabr",
            "Oktabr",
            "Noyabr",
            "Dekabr"
          ],
          datasets: [
            {
              label: 'Tugallangan',
              backgroundColor: "#05f74e",
              borderColor: "#05f74e",
              data: this.statistics.completed,
              fill: false,
            },
            {
              label: 'Bekor qilingan',
              fill: false,
              backgroundColor: "#f70505",
              borderColor: "#f70505",
              data: this.statistics.canceled
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Orders Chart",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          legend: {
            labels: {
              fontColor: "rgba(0,0,0,.4)",
            },
            align: "end",
            position: "bottom",
          },
          scales: {
            xAxes: [
              {
                display: false,
                scaleLabel: {
                  display: true,
                  labelString: "Month",
                },
                gridLines: {
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.3)",
                  zeroLineColor: "rgba(33, 37, 41, 0.3)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                },
                gridLines: {
                  borderDash: [2],
                  drawBorder: false,
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.2)",
                  zeroLineColor: "rgba(33, 37, 41, 0.15)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      let ctx = document.getElementById("bar-chart").getContext("2d");
      const lineChart = new Chart(ctx, config);
      this.fetchData().then(() => lineChart.update())
    });
  },
  methods: {
    async fetchData() {
      const res = await this.$store.dispatch('get', {url:'/admin/statistic/booking/statuses'})
      let chartData = res.data.bookings
      for (const key in chartData) {
        this.statistics.completed[key - 1] = chartData[key]["1"]
        this.statistics.canceled[key - 1] = chartData[key]["0"]
      }
    }
  },
};
</script>
