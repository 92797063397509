import {createApp} from 'vue'

import router from '@/router';
import store from './store';

import App from './App.vue';
import io from './modules/socket';

import {mask} from '@/utils/mask';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Toast, {options} from "@/modules/toastification";


createApp(App)
    .use(router)
    .use(store)
    .use(io(store))
    .use(Toast, options)
    .use(VueSweetalert2)
    .mixin(mask)
    .mount('#app')
