import {createStore} from "vuex";

import {storageName} from "@/config";
import request from "@/utils/request";

import {parse, stringify} from "@/utils/serializer";
import {getRole, getToken} from "@/utils/storage";


export default createStore({
    state: {
        config: {
            headers: {
                Authorization: "Bearer " + getToken(storageName)
            }
        },
        role: getRole(storageName),
        token: getToken(storageName),
        notifications: [],
        form: {
            phone: ""
        },
    },
    getters: {
        config(state) {
            return parse(stringify(state.config))
        }
    },
    mutations: {
        setAuth(state) {
            state.token = getToken(storageName);
            state.role = getRole(storageName);

            state.config.headers.Authorization = "Bearer " + state.token;
        },
        setHeader(state, payload) {
            // let key = Object.keys(payload)[0];
            state.config.headers = {
                ...state.config.headers,
                ...payload
            };
        },
        SOCKET_GET_MESSAGE(state, message) {
            state.notifications.push(message)
        },
        FILL_BOOKING_FORM(state, payload) {
            state.form.phone = payload
        }
    },
    actions: {
        // eslint-disable-next-line
        async get({dispatch, commit}, {url, params = {}}) {
            let res = await request.get(url, {...this.getters.config, params: params});
            return res.data;
        },
        // eslint-disable-next-line
        async post({dispatch, commit}, {url, model}) {
            let res = await request.post(url, model, {...this.getters.config});
            return res.data;
        },
        // eslint-disable-next-line
        async put({dispatch, commit}, {url, model}) {
            let res = await request.put(url, model, {...this.getters.config});
            return res.data;
        },
        // eslint-disable-next-line
        async delete({}, url) {
            let res = await request.delete(url, {...this.getters.config});
            return res.data
        },
        // eslint-disable-next-line
        async login({}, data) {
            try {
                let res = await request.post('/login', data)
                return res.data;
            } catch (e) {
                // if (e instanceof NetworkError)
                return "Tarmoqda xatolik";
            }
        },
        logout({dispatch, commit}) {
            console.log(dispatch, commit)
        }
    },
    modules: {}
});
