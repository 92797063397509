export function formatMonthDay(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        hour = d.getHours().toString().padStart(2, '0'),
        minute = d.getMinutes().toString().padStart(2, '0');

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month].join('/') + ' ' + hour + ':' + minute;
}
