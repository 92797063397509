<template>
  <table-layout heading="Tumanlar" :headers="headers" link="/branches/create">
    <tr v-for="(model, i) in data" :key="model.id">
      <td :class="rowClass">
        {{ i + 1 }}
      </td>
      <th :class="rowClass" style="text-align: left">
        {{ model.name }}
      </th>
      <td :class="rowClass">
        {{ model.phone }}
      </td>
      <td :class="rowClass">
        {{ formatDate(model.created_at) }}
      </td>
      <td :class="rowClass + 'text-right'">
        <table-dropdown url="/branches" :id="model.id" :original="url + '/' + model.id"/>
      </td>
    </tr>
  </table-layout>
</template>

<script>
import avatar from "@/assets/img/avatar.svg";
import TableLayout from "@/views/components/Tables/TableLayout";
import TableDropdown from "@/views/components/Dropdowns/TableDropdown";
import {formatDate} from "@/utils/map";

export default {
  name: "branches-index",
  methods: {formatDate},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      rowClass: "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4",
      avatar,
      headers: ["Tr", "Manzil", "Telefon", "Sana", "Tahrirlash"],
      data: [],
      url: '/admin/branches'
    }
  },
  components: {
    TableLayout,
    TableDropdown
  },
  async mounted() {
    try {
      const res = await this.$store.dispatch('get', {url: this.url})
      this.data = res.data.branches
    } catch (e) {
      throw new Error(e)
    }
  }
}
</script>
