<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700"
  >
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
            Buyurtmalar soni
          </h6>
          <h2 class="text-white text-xl font-semibold">
            Toifasi bo&#8216;yicha
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto">
      <!-- Chart -->
      <div class="relative h-350-px">
        <canvas id="line-chart"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
export default {
  data() {
    return {
      statistics: {
        operator: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        mobile: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        street: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        bot: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      var config = {
        type: "line",
        data: {
          labels: [
            "Yanvar",
            "Fevral",
            "Mart",
            "Aprel",
            "May",
            "Iyun",
            "Iyul",
            "Avgust",
            "Sentabr",
            "Oktabr",
            "Noyabr",
            "Dekabr"
          ],
          datasets: [
            {
              label: 'Operator',
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: this.statistics.operator,
              fill: false,
            },
            {
              label: 'Mobil ilova',
              fill: false,
              backgroundColor: "#fff",
              borderColor: "#fff",
              data: this.statistics.mobile
            },
            {
              label: 'Ko\u2018chadan',
              fill: false,
              backgroundColor: "#f5ed05",
              borderColor: "#f5ed05",
              data: this.statistics.street
            },
            {
              label: 'Bot orqali',
              fill: false,
              backgroundColor: "#f705d7",
              borderColor: "#f705d7",
              data: this.statistics.bot
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Sales Charts",
            fontColor: "white",
          },
          legend: {
            labels: {
              fontColor: "white",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Month",
                  fontColor: "white",
                },
                gridLines: {
                  display: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.3)",
                  zeroLineColor: "rgba(0, 0, 0, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                  fontColor: "white",
                },
                gridLines: {
                  borderDash: [3],
                  borderDashOffset: [3],
                  drawBorder: false,
                  color: "rgba(255, 255, 255, 0.15)",
                  zeroLineColor: "rgba(33, 37, 41, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      var ctx = document.getElementById("line-chart").getContext("2d");
      const lineChart = new Chart(ctx, config);
      this.fetchData().then(() => lineChart.update())
    });
  },
  methods: {
    async fetchData() {
        const res = await this.$store.dispatch('get', {url:'/admin/statistic/booking/categories'})
        let chartData = res.data.bookings
        for (const key in chartData) {
          this.statistics.operator[key - 1] = chartData[key]["1"]
          this.statistics.mobile[key - 1] = chartData[key]["2"]
          this.statistics.street[key - 1] = chartData[key]["3"]
          this.statistics.bot[key - 1] = chartData[key]["4"]
        }
    }
  }
};
</script>
